<template>
    <RecordingOnboardingDialog @dismiss="handleDismissedRecordingSetup" @complete="handleCompleteRecordingSetup" />
    <RecordingTestDialog @dismiss="handleTextboxShow" @mic-selected="handleMicSelection" />
    <div ref="btnNav" class="shrink-0 h-auto min-h-10 lg:min-h-16" autocomplete="off">
        <div class="grid grid-cols-10 md:flex md:flex-row items-center justify-around w-full max-w-full md:max-w-screen-md mx-auto gap-4 p-4 pl-8 md:py-8 md:pt-4 relative">
            <div class="relative col-span-8 grow">
                <ul
                    v-if="files.length > 0"
                    class="absolute inset-x-5 md:inset-x-20 bottom-8 flex justify-start gap-4 bg-gray-100/50 pt-4 px-8 pb-10 border-2 rounded-lg md:max-w-screen-md mx-auto z-10 flex-wrap overflow-auto max-h-60"
                >
                    <li v-for="file in files" :key="file.name">
                        <FileAttachedTag :file-description="file.description" :file-name="file.name" :remove="() => useChatStore.removeFile(file.name)" />
                    </li>
                </ul>
                <div
                    v-if="showTextbox"
                    class="rounded-3xl w-full leading-[0] py-1.5 pr-2 pl-4 scroll-none textarea-autosize bg-white flex flex-row gap-4 items-center has-[textarea:focus-visible]:outline has-[textarea:focus-visible]:outline-gray-300/20 shadow-sm shadow-black/5 relative z-20"
                >
                    <textarea
                        ref="textarea"
                        v-model="content"
                        class="grow focus:outline-transparent focus-visible:outline-transparent outline-transparent leading-normal text-base"
                        :placeholder="textPlaceholder"
                        data-cy="chat-controls_text-input"
                        autofocus
                        autocomplete="off"
                        @keydown="handleKeyDown"
                        @keydown.enter="handleSendMessage"
                        @paste="handlePaste"
                    ></textarea>
                    <button
                        class="focus-visible:outline-none focus-visible:text-valence-pink/60"
                        type="button"
                        :disabled="isSending"
                        :title="(content.length > 0 || files.length > 0) && 'Send'"
                        :class="(content.length === 0 && files.length === 0) || isSending ? 'text-valence-pink-600/20' : 'text-valence-pink-600 hover:text-valence-pink/80'"
                        data-cy="chat-controls_text-submit"
                        @click="handleSendMessage"
                    >
                        <i class="bi bi-arrow-right-circle-fill text-3xl"></i>
                    </button>
                </div>
                <template v-else>
                    <div v-if="!isTranscribing && !isRecording" class="w-full md:tooltip tooltip-right md:tooltip-top" data-tip="Talk to Nadia">
                        <button
                            type="button"
                            class="relative rounded-xl text-sm md:text-base font-semibold py-3 px-4 leading-normal w-full shadow-sm shadow-[#FF2891]/30 bg-valence-pink-600 text-white hover:bg-valence-pink-600/90 disabled:opacity-50"
                            :disabled="isPreparingToRecord"
                            @click="handleRecordingStart"
                        >
                            {{ recordingButtonText }}
                        </button>
                    </div>
                    <div
                        v-if="isTranscribing"
                        class="md:tooltip w-full"
                        :class="(showSlowTranscription ? 'tooltip-open ' : ' ') + (showTextbox ? ' tooltip-right md:tooltip-top' : ' tooltip-top')"
                        :data-tip="showSlowTranscription ? 'Still transcribing...\n(Click to Cancel)' : `Transcribing...`"
                        @click="handleTranscriptionCancel"
                    >
                        <div class="flex-nowrap relative btn btn-ghost btn-circle btn-sm h-[2.5rem] md:h-[3rem] w-auto disabled bg-neutral-100 hover:bg-neutral-50">
                            <div class="ml-4 waveform_bars relative w-6 h-6 md:w-8 md:h-8 p-0 gap-1 flex items-center">
                                <div class="block h-2 bg-black rounded ripple-grow"></div>
                                <div class="block h-4 bg-black rounded ripple-grow"></div>
                                <div class="block h-8 bg-black rounded ripple-grow"></div>
                                <div class="block h-6 bg-black rounded ripple-grow"></div>
                                <div class="block h-3 bg-black rounded ripple-grow"></div>
                            </div>
                            <div class="mr-4" :class="showTextbox ? 'text-xs md:text-md' : ''">
                                <span :class="showTextbox ? 'hidden md:inline' : ''">Transcribing </span>
                                <span>{{ recordingTime }}</span>
                                <span class="hidden md:inline">...</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isTranscribing && isRecording" class="w-full md:tooltip tooltip-left md:tooltip-top h-[2.5rem] md:h-[3rem]" :data-tip="'Stop Recording'">
                        <div
                            class="flex-nowrap relative btn btn-ghost bg-none btn-circle btn-sm h-[2.5rem] md:h-[3rem] w-auto bg-valence-pink-transparent text-white border-2"
                            :style="micBorderStyles"
                            @click="recorder.stop"
                        >
                            <svg
                                class="w-6 h-6 lg:w-8 lg:h-8"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                fill="currentColor"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                enable-background="new 0 0 512 512"
                                stroke="#FFFFFF"
                                stroke-width="4.608"
                            >
                                <g id="micRed" stroke-width="0"></g>
                                <g id="" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="">
                                    <g>
                                        <g>
                                            <path
                                                d="m439.5,236c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,70-64,126.9-142.7,126.9-78.7,0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,86.2 71.5,157.4 163.1,166.7v57.5h-23.6c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h88c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z"
                                            ></path>
                                            <path
                                                d="m256,323.5c51,0 92.3-41.3 92.3-92.3v-127.9c0-51-41.3-92.3-92.3-92.3s-92.3,41.3-92.3,92.3v127.9c0,51 41.3,92.3 92.3,92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3,23.5 52.3,52.3v127.9c0,28.8-23.5,52.3-52.3,52.3s-52.3-23.5-52.3-52.3v-127.9z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div ref="levelsContainer" class="grow flex gap-0 items-center justify-center">
                                <div
                                    v-for="(level, idx) in recentRecordingLevels"
                                    :key="idx"
                                    class="block bg-valence-pink"
                                    :style="`width:1px; height: ${(level / 1.5 > 30 ? 30 : level / 1.5) + 1}px;`"
                                ></div>
                            </div>
                            <div class="font-light mr-2 w-6 lg:w-10 text-right timer text-xs lg:text-md lg:mr-auto">{{ recordingTime }}</div>
                            <div class="text-valence-pink mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-stop-circle h-6 w-6 lg:h-8 lg:w-8" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5z" />
                                </svg>
                            </div>
                            <div
                                ref="micIndicator"
                                class="absolute bg-valence-pink w-2 -ml-1 md:ml-0 md:mb-[0.25rem] block rounded-md mic-indicator"
                                :style="micIndicatorStyles"
                            ></div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="!isInIFrame()" class="col-span-1 shrink-0 leading-[0]">
                <button
                    v-if="showTextbox"
                    :disabled="isRecording"
                    class="text-valence-grey-600 hover:text-valence-grey-600/80 hover:opacity-8 md:tooltip tooltip-right md:tooltip-top"
                    data-tip="Use voice"
                    type="button"
                    @click="handleTextboxHide"
                >
                    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.332 8.33333C10.332 10.1742 8.83953 11.6667 6.9987 11.6667C5.15786 11.6667 3.66536 10.1742 3.66536 8.33333V3.33333C3.66536 1.4925 5.15786 0 6.9987 0C8.83953 0 10.332 1.4925 10.332 3.33333V8.33333ZM13.6654 6.66667V8.33333C13.6654 12.015 10.6804 15 6.9987 15C3.31703 15 0.332031 12.015 0.332031 8.33333V6.66667H1.9987V8.33333C1.9987 11.0908 4.2412 13.3333 6.9987 13.3333C9.7562 13.3333 11.9987 11.0908 11.9987 8.33333V6.66667H13.6654ZM7.83203 17.525V15.8333H6.16536V17.525C4.2637 17.6408 2.83203 18.145 2.83203 18.75C2.83203 19.4408 4.69703 20 6.9987 20C9.30037 20 11.1654 19.4408 11.1654 18.75C11.1654 18.145 9.7337 17.6408 7.83203 17.525Z"
                            fill="currentColor"
                        />
                    </svg>
                </button>
                <button
                    v-else
                    :disabled="isRecording"
                    class="text-valence-grey-600 hover:text-valence-grey-600/80 md:tooltip tooltip-right md:tooltip-top"
                    data-tip="Use text"
                    title="Use text"
                    type="button"
                    data-cy="chat-controls_text-toggle"
                    @click="handleTextboxShow"
                >
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.3327 0H0.666016V5H2.33185C2.33185 4.02167 2.66268 2.5 3.99935 2.5H7.33268V16.5617C7.33268 17.54 6.64518 18.3333 5.66602 18.3333H4.83268V20H13.1652V18.3333H12.3327C11.3543 18.3333 10.666 17.54 10.666 16.5617V2.5H13.9993C15.3735 2.5 15.6827 4.02167 15.6827 5H17.3327V0Z"
                            fill="currentColor"
                        />
                    </svg>
                </button>
                <audio id="audioPlayer" ref="audioPlayer" class="hidden"></audio>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useTextareaAutosize } from "@vueuse/core";
import FileAttachedTag from "~vue/components/FileAttachedTag.vue";
import { CHAT_EVENT } from "~vue/events.js";
import RecordingOnboardingDialog from "~vue/RecordingOnboardingDialog.vue";
import RecordingTestDialog from "~vue/RecordingTestDialog.vue";
import { useChatStore } from "~vue/stores/chatStore.js";
import { isInIFrame } from "~vue/utils.js";
import { logUserInteraction } from "~vue/utils/logUtils.js";
import DOMPurify from "dompurify";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import { AudioRecorder, msToDisplayTime } from "../../AudioRecorder.js";

const DOCUMENT_LENGTH_THRESHOLD = 1000;

const props = defineProps({
    recordingOnboardingEnabled: Boolean,
    isTranscribing: Boolean,
    isSending: Boolean,
    recordingButtonText: String,
    textPlaceholder: String,
    showSlowTranscription: Boolean,
    defaultTextInput: {
        type: Boolean,
        default: () => false,
    },
});

const emit = defineEmits(["textbox-show", "text-keydown", "text-send", "recording-start", "recording-complete", "recording-error", "transcription-cancel"]);

const { emitter } = inject("globalProperties");

const content = ref("");
const recorder = ref(
    new AudioRecorder({
        levelCheckIntervalMs: 200,
        // Before the AudioRecorder, there was no time limit logic, so I'm setting this to
        // an hour for now until we can discuss what an appropriate time limit would be.
        maxRecordingTimeSecs: 60 * 60,
    }),
);

// template refs
const audioPlayer = ref(null);
const btnNav = ref(null);

const audioPlaying = ref(false);
const currentReplyMessageId = ref(null);
const dictationChunkCount = ref(0);
const dictationOn = computed(() => useChatStore.dictationOn);
const isRecording = ref(false);
const isPreparingToRecord = ref(false);
const queuedDictationChunks = ref([]);
const recordingAudioLevelHistory = ref([]);
const recordingTime = ref("");
const recordingLevel = ref(0);
const showTextbox = ref(props.defaultTextInput || isInIFrame());
const showRecordingOnboarding = ref(props.recordingOnboardingEnabled);
const files = computed(() => useChatStore.files);

const { textarea } = useTextareaAutosize({ input: content });

const micBorderStyles = computed(() => {
    if (isRecording.value) {
        return `background: rgba(255,40,144,0.2); border-color: rgba(255,40,144,0.8); border-width: 3px; padding-left: 0.25rem; padding-right: 0.25rem; color:#ff2891; fill:#ff2891;`;
    }
    return "";
});
const micIndicatorStyles = computed(() => {
    if (isRecording.value) {
        let level = recordingLevel.value / 50 + 0.2;
        if (level > 1) {
            level = 1;
        }
        return `bottom: 14px; left: 16px; height: ${level}rem; color:#ff2891; fill:#ff2891;`;
    }
    return "bottom: 14px; left: 16px;  color: #ff2891; fill:#ff2891;";
});
const recentRecordingLevels = computed(() => {
    const breakpoints = [
        [1200, 360],
        [1000, 240],
        [800, 180],
        [600, 120],
        [400, 90],
    ];
    // On mobile, show half as much.
    let LEVELS_LENGTH = 480;
    for (const breakpoint of breakpoints) {
        if (window.innerWidth < breakpoint[0]) {
            LEVELS_LENGTH = breakpoint[1];
        }
    }

    if (recordingAudioLevelHistory.value.length >= LEVELS_LENGTH) {
        return recordingAudioLevelHistory.value.slice(-LEVELS_LENGTH);
    } else {
        let filledArray = new Array(LEVELS_LENGTH).fill(0);

        if (recordingAudioLevelHistory.value.length > 0) {
            filledArray.splice(-recordingAudioLevelHistory.value.length, recordingAudioLevelHistory.value.length, ...recordingAudioLevelHistory.value);
        }
        return filledArray;
    }
});

watch(showTextbox, (value) => {
    if (value) {
        logUserInteraction("turned_on_text_input");
        nextTick(() => {
            textarea.value.focus();
        });
    }
});

onMounted(() => {
    emitter.on(CHAT_EVENT.DICTATION_EVENT, handleDictationEvent);
    emitter.on(CHAT_EVENT.SET_MESSAGE_TEXT, handleSetMessageTextEvent);
    emitter.on(CHAT_EVENT.OPEN_TEXT_INPUT, handleTextboxShow);
    recorder.value.on("silence", handleRecorderSilence);
    recorder.value.on("level", handleRecorderLevel);
    recorder.value.on("stop", handleRecorderStopped);
    recorder.value.on("finish", handleRecorderFinished);

    audioPlayer.value?.addEventListener("ended", handleAudioFinished);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.DICTATION_EVENT, handleDictationEvent);
    emitter.off(CHAT_EVENT.SET_MESSAGE_TEXT, handleSetMessageTextEvent);
    emitter.off(CHAT_EVENT.OPEN_TEXT_INPUT, handleTextboxShow);

    recorder.value.off("silence", handleRecorderSilence);
    recorder.value.off("level", handleRecorderLevel);
    recorder.value.off("stop", handleRecorderStopped);
    recorder.value.off("finish", handleRecorderFinished);

    audioPlayer.value?.removeEventListener("ended", handleAudioFinished);
});

const getControlsHeight = () => btnNav.value.clientHeight;
const handleSetMessageTextEvent = (text) => (content.value = text);
const handleAudioFinished = () => {
    if (dictationOn.value && queuedDictationChunks.value.length > 0) {
        let next = queuedDictationChunks.value.shift();

        // Create a URL for the Blob and set it as the src of the audio element
        audioPlayer.value.src = URL.createObjectURL(next.blob);
        dictationChunkCount.value = next.num;
        let play_promise = audioPlayer.value.play();
        if (play_promise !== undefined) {
            play_promise
                .then((_) => {
                    // Autoplay started.
                    audioPlaying.value = true;
                })
                .catch(() => {
                    // Autoplay was prevented because the user hasn't take an action.
                    // TODO: consider showing a "play" button so that user can start playback.
                    audioPlaying.value = false;
                    logUserInteraction("coach_dictation_prevented");
                });
        }
    } else {
        audioPlaying.value = false;
        logUserInteraction("coach_dictation_stop");
    }
};

const handleDictationEvent = (data) => {
    if (!isRecording.value && (data.data.dictation_count > dictationChunkCount.value || data.data.dictation_count == 1 || !audioPlaying.value)) {
        if (data.data.dictation_count == 1) {
            queuedDictationChunks.value = [];
            currentReplyMessageId.value = data.data.message_id;
            logUserInteraction("coach_dictation_start");
        } else {
            if (currentReplyMessageId.value != data.data.message_id) {
                return;
            }
        }
        const audioStream = data.data.audio_stream;
        const byteCharacters = atob(audioStream);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "audio/mp3" });
        queuedDictationChunks.value.push({ num: data.data.dictation_count, blob: blob, sentAt: data.data.sentAt });
    }
    if (dictationOn.value && !audioPlaying.value) {
        handleAudioFinished();
    }
};

const handleTextboxHide = () => (showTextbox.value = false);
const handleTextboxShow = () => {
    if (showRecordingOnboarding.value) {
        logUserInteraction("onboarding_viewed_enable_voice");
        emitter.emit(CHAT_EVENT.OPEN_ONBOARDING_DIALOG);
        return;
    }

    showTextbox.value = true;
    emit("textbox-show");
};

const handleTranscriptionCancel = () => emit("transcription-cancel");
const handleSendMessage = (event) => {
    if (!event.shiftKey) {
        event.preventDefault();
    }

    if (props.isSending) {
        return;
    }

    if (!event.shiftKey) {
        const clean = DOMPurify.sanitize(content.value, { FORBID_TAGS: ["a"] });
        emit("text-send", { content: clean, files: files.value });
        useChatStore.removeAllFiles();
    }
};

const handleCompleteRecordingSetup = () => (showRecordingOnboarding.value = false);
const handleDismissedRecordingSetup = () => {
    logUserInteraction("onboarding_viewed_voice_declined");
    showRecordingOnboarding.value = false;
    handleTextboxShow();
};
const handleKeyDown = () => emit("text-keydown");
const handleRecorderSilence = () => {
    recorder.value.stop({ abort: true });
    openTestDialog();
};
const handleRecorderLevel = (level) => {
    recordingLevel.value = level * 100;
    recordingAudioLevelHistory.value.push(level * 100);
    recordingTime.value = msToDisplayTime(recorder.value.recordingTimeMs);
};
const handleRecorderStopped = () => {
    isRecording.value = false;
    recordingTime.value = msToDisplayTime(recorder.value.recordingTimeMs);
    logUserInteraction("user_recording_ended");
};
const handleRecorderFinished = ({ blob, recordingTimeMs }) => {
    emit("recording-complete", {
        blob,
        recordingTime: msToDisplayTime(recordingTimeMs),
    });
};
const handleRecordingStart = async () => {
    logUserInteraction("user_recording_start");
    if (showRecordingOnboarding.value) {
        logUserInteraction("onboarding_viewed_enable_voice");
        emitter.emit(CHAT_EVENT.OPEN_ONBOARDING_DIALOG);
        return;
    }

    stopDictation();
    isPreparingToRecord.value = true;
    recordingAudioLevelHistory.value = [];
    recordingTime.value = "";
    recordingLevel.value = 0;

    try {
        await recorder.value.start();
        isRecording.value = true;
        emit("recording-start");
    } catch (e) {
        switch (e.name) {
            case "NotAllowedError":
            case "OverconstrainedError":
            case "NotFoundError":
                openTestDialog();
                break;
            default:
                emit("recording-error");
        }

        if ("Sentry" in window) {
            window.Sentry.captureException(e);
        }

        logUserInteraction("mic_error");
    } finally {
        isPreparingToRecord.value = false;
    }
};
const openTestDialog = () => emitter.emit(CHAT_EVENT.OPEN_MIC_TEST_DIALOG);
const stopDictation = () => {
    audioPlayer.value.pause();
    audioPlayer.value.src = "";
    queuedDictationChunks.value = [];
    audioPlaying.value = false;
};
const handleMicSelection = (event) => recorder.value.setAudioInputDeviceId(event.deviceId);
// NOTE: For the future "File Uploads" feature see `chat.actions.file_upload`
// 1. UI Component here sends a `file_upload` action code (see `transcription_request` for an example) and gets a `signed_url` in response.
// 2. Client then uploads the file to the `signed_url` and gets the `file_url` or `object_id` in response.
// 3. Once the file is uploaded, the client holds this state and sends a `file_uploaded/object_id` property along with the message when clicking "Send".
const handlePaste = (event) => {
    if (event.clipboardData.getData("text/plain").length > DOCUMENT_LENGTH_THRESHOLD) {
        event.preventDefault();
        const NUM_BYTES = 100;
        const now = new Date();
        useChatStore.addFile({
            name: `pasted-content-${now.toISOString()}.txt`,
            content: event.clipboardData.getData("text/plain"),
            description: event.clipboardData.getData("text/plain").slice(0, NUM_BYTES).replace(/\n/g, " ") + "...",
        });
    }
};

defineExpose({
    getControlsHeight,
});
</script>

<style type="postcss">
[autocomplete="off"] div[data-lastpass-icon-root="true"] {
    display: none;
}

[autocomplete="off"] div[data-lastpass-infield="true"] {
    display: none;
}

@keyframes rippleEffect {
    0% {
        opacity: 0.4;
        transform: scaleY(50%);
    }

    50% {
        opacity: 0.7;
        transform: scaleY(100%);
    }

    100% {
        opacity: 0.3;
        transform: scaleY(50%);
    }
}

.ripple-grow {
    animation: rippleEffect 2s infinite ease-in-out;
    opacity: 0.4;
    transform: scaleY(50%);
    width: 6px;
    border-radius: 999px;
}

.ripple-grow:nth-child(1) {
    animation-delay: 0s;
}

.ripple-grow:nth-child(2) {
    animation-delay: 0.4s;
}

.ripple-grow:nth-child(3) {
    animation-delay: 0.8s;
}

.ripple-grow:nth-child(4) {
    animation-delay: 1.2s;
}

.ripple-grow:nth-child(5) {
    animation-delay: 1.6s;
}

.timer {
    letter-spacing: 1.8px;
}

.timer.text-xs {
    letter-spacing: 1.2px;
    margin-left: -0.5rem;
}

textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

textarea::-webkit-scrollbar {
    display: none;
}

textarea::-webkit-resizer {
    display: none;
}

.textarea-autosize {
    overflow: hidden;
}

.textarea-autosize.oversize_y {
    overflow-y: auto;
}

/* Hide scrollbar for IE, Edge and Firefox */
.textarea-autosize.oversize_y {
    overflow-y: auto;
}
</style>
