<template>
    <dialog id="recording-onboarding-dialog" ref="dialogElement" class="bg-white md:p-16 p-4 rounded-xl text-valence-grey-800">
        <div class="flex flex-col gap-4 text-center max-w-prose">
            <div class="text-xl font-bold">{{ title }}</div>
            <p v-if="state === 'initial'" class="max-w-xs mx-auto text-base">
                Enable microphone access to reply with ease. <strong>Users who enable mic access get coaching insights 2x as fast.</strong>
            </p>
            <p v-if="state === 'skipping'" class="max-w-md mx-auto text-base">
                We understand. We built Nadia to be privacy-first. Your microphone remains off unless you explicitly turn it on and turns off immediately when you press "Stop."<br /><br />
                We only use your microphone to transcribe your voice, and all data is encrypted and protected by our privacy policies.
            </p>
            <div class="mt-4">
                <button type="button" autofocus class="rounded-xl text-white bg-valence-pink-600 hover:bg-valence-pink-600/80 font-bold text-sm py-3 px-6" @click="requestPermission">
                    Set Up Microphone
                </button>
            </div>
            <button type="button" class="mt-4 text-xs underline text-valence-grey-800/75 hover:text-valence-grey-800/50" @click="skip">Skip microphone access</button>
        </div>
    </dialog>
</template>

<script>
import { ref } from "vue";
import { CHAT_EVENT } from "./events.js";

const STATES = {
    INITIAL: "initial",
    SKIPPING: "skipping",
};

export default {
    name: "RecordingOnboardingDialog",
    emits: ["complete", "dismiss"],
    setup() {
        return {
            dialogElement: ref(null),
            state: ref(STATES.INITIAL),
        };
    },
    computed: {
        title() {
            switch (this.state) {
                case STATES.INITIAL:
                    return "Let's talk about it";
                case STATES.SKIPPING:
                    return "Have concerns about turning on your mic?";
            }
            return "";
        },
    },
    mounted() {
        if (this.dialogElement) {
            // Disables closing the modal using the `Esc` key
            this.dialogElement.addEventListener("cancel", (event) => {
                event.preventDefault();
            });
        }

        this.emitter.on(CHAT_EVENT.OPEN_ONBOARDING_DIALOG, this.open);
    },
    unmounted() {
        this.emitter.off(CHAT_EVENT.OPEN_ONBOARDING_DIALOG, this.open);
    },
    methods: {
        async requestPermission() {
            try {
                await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                this.dialogElement.close();
                this.$emit("complete");
            } catch (e) {
                this.dismiss();
            }
        },
        dismiss() {
            this.dialogElement.close();
            this.$emit("dismiss");
        },
        skip() {
            if (this.state === STATES.INITIAL) {
                this.state = STATES.SKIPPING;
                return;
            }

            this.dismiss();
        },
        open() {
            this.dialogElement.showModal();
        },
    },
};
</script>

<style type="postcss">
dialog#recording-onboarding-dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
