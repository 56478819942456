<template>
    <div class="relative">
        <button v-if="remove" class="absolute -top-1 -right-1 z-50" @click="remove">
            <span class="border rounded-full bg-valence-pink-200 h-6 w-6 flex items-center justify-around">
                <span class="bi bi-x text-white text-lg leading-4"></span>
            </span>
        </button>
        <div class="relative flex flex-col justify-between rounded-lg p-2 pl-3 border-2 border-valence-grey-200 bg-white text-xs h-36 w-32 fileAttachment">
            <div v-if="fileDescription" class="fileDescription text-clip overflow-hidden text-black pr-2">{{ fileDescription }}</div>
            <div class="flex justify-center">
                <div class="border-2 border-valence-grey-100 bg-valence-blue-mid/45 rounded-badge py-[2px] px-4 font-semibold">{{ fileType }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FileAttachedTag",
    props: {
        fileName: { type: String, required: true },
        fileDescription: { type: String, default: "" },
        fileType: { type: String, default: "txt" },
        remove: { type: Function, default: null },
        class: { type: String, default: "" },
    },
};
</script>

<style scoped>
.fileAttachment {
    position: relative;
    overflow: hidden;
    clip-path: polygon(85% 0, 100% 20px, 100% 100%, 0 100%, 0 0);
}

.fileAttachment:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 19px 19px 0;
    border-style: solid;
    border-color: #fff rgb(223, 223, 223) transparent;
    @apply shadow-lg shadow-gray-300 z-50;
}

.fileDescription {
    position: relative;
}

.fileDescription:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2lh;
    width: 100%;
    background: linear-gradient(0deg, #fff, transparent);
}
</style>
